import api from "../../../config/api";
import { submitFormRequest, submitFormFailure, submitFormSuccess, submitRopFormRequest, submitRopFormFailure, submitRopFormSuccess } from "../slice/myThaiCRMFormSlice";
import { put, takeLatest, call } from "redux-saga/effects";
import { config } from "../../../config/global";
const myThaiCRMFormData = async payload => {
  const POST_API_URL = config.MYTHAI_CRM_FORM_FLIGTH_SUPPORT;
  const headerData = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  let formSubmitPayload = {
    bookingEmail: payload.bookingEmail,
    pnr: payload.pnr,
    description: payload.description,
    ticketNumber: payload.ticketNumber,
    category: payload.category,
    // portalCaseTopic: payload.portalCaseTopic,
    portalCaseSubTopic: payload.portalCaseTopic,
    onlineBookingSupportTopic: payload.topic,
    attachmentList: payload.attachmentList,
    passportNumber: payload.passportNumber,
    flightNumber: payload.flightNumber
  };
  const response = await api.post(POST_API_URL, formSubmitPayload, headerData);
  return response;
};
function* postFormSubmitData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(myThaiCRMFormData, payload);
    yield put(submitFormSuccess(response.data));
  } catch (error) {
    yield put(submitFormFailure(error));
  }
}
const myThaiRopFormData = async payload => {
  const POST_API_URL = config.MYTHAI_ROP_FORM_FLIGTH_SUPPORT;
  const headerData = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  let formSubmitPayload = {
    ropNumber: payload.ropNumber,
    description: payload.description,
    portalCaseSubTopic: "Royal Orchid Plus general inquiries",
    ropGeneralInquiry: payload.inquiries,
    flightDetails: [],
    attachmentList: []
  };
  const response = await api.post(POST_API_URL, formSubmitPayload, headerData);
  return response;
};
function* postRopFormSubmitData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(myThaiRopFormData, payload);
    yield put(submitRopFormSuccess(response.data));
  } catch (error) {
    yield put(submitRopFormFailure(error));
  }
}
export function* watchMyThaiCRMFormData() {
  yield takeLatest(submitFormRequest.type, postFormSubmitData);
}
export function* watchMyThaiRopFormData() {
  yield takeLatest(submitRopFormRequest.type, postRopFormSubmitData);
}